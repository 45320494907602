<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data User</h4> 
            <a href="#/user/add" class="float-right btn btn-success form-control-sm">Tambah Data Baru</a>

          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table ref="table" class="table" id="datatable" :options="{ deleteUser }">
        <!-- <table class="table" id="datatable"> -->
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Username </th>
              <th>Nama </th>
              <th>Email </th>
              <th>Edit</th>
              <th>Hapus</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deleteUser()" color="danger">Hapus</CButton>
      </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 

export default {
  
  filters: {
    rupiah: function(value) {
      
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();
    
    let us = JSON.parse(this.$store.state.users);
      // alert(us.name);
      if(us.level=='1'){
        this.add= true;
      }
  },
  data: function() {
        return {
            deleteModal : false,
            idselected:"0",
            products:[],
            add:false,
            items:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    confirmDelete: function(id){
      this.idselected = id;
      this.deleteModal = true;
    },
    deleteUser: function(){
      
      axios.delete(process.env.VUE_APP_BASE_URL+"user/"+this.idselected)
        .then((response) => {
            
            if(response.status==200){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }
            
        })
    },
    loadData: function() {
      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/user/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "username" },
                  { "data": "nickname" },
                  { "data": "email" },
                  { "data": "edit" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            alert(data.id_user);
                            return "<button @click.prevent='deleteUser(`"+data.id_user+"`)' data-id='$r[id_user]' class='btn btn-sm btn-danger btnhapus btn2' >Hapus</button>";
                        }, 
                    },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
       
       const table = $(this.$refs.table).DataTable({
          // $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"user/datatable/",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "username" },
                  { "data": "nickname" },
                  { "data": "email" },
                  { "data": "edit" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            // alert(data.id_penomoran);
                            return "<button @click.prevent='deleteUser(`"+row.id+"`)' class='btn btn-sm btn-danger btnhapus btn2' >Hapus</button>";
                        }, 
                    },
              ]            
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';

          const self = this
          $('tbody', this.$refs.table).on( 'click', '.btnhapus', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              self.confirmDelete(objdata.id)
          });
        // })
      }
      
    }
  }
}
</script>